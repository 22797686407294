.table {
    width: 100%;
    border-collapse: collapse;
    border: none;

    .th-sortable {
        cursor: pointer;
        padding-right: 35px;
    }

    .sort-icon {
        width: 15px;
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #fff;

        &.inactive {
            opacity: .2;
        }
    }

    tr {
        td, th {
            position: relative;
            font-size: 14px;
            border: 1px solid #E3E3E3;
            padding: 0.85rem;
            text-align: left;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }

        td > img {
            height: 110px;
            width: 110px;
            object-fit: contain;
        }

        td > img#toggle-img {
            height: 32px;
            width: 32px;
            object-fit: contain;
        }

        th {
            color: $text-secondary-color;
            font-weight: 500;
        }

        td.no-data {
            color: $text-secondary-color;
        }

        &.has-link {
            cursor: pointer;
        }

        &:last-child {
            td {
                border-bottom: none;
            }
        }

        &:first-child {
            td {
                border-top: none;
            }
            th {
                border-top: none;
            }
        }
    }
}